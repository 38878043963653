// extracted by mini-css-extract-plugin
export var root = "sample-module--root--2fa52";
export var blogContainer = "sample-module--blogContainer--5792e";
export var excerpt = "sample-module--excerpt--9e831";
export var footerContainer = "sample-module--footerContainer--749b5";
export var socialMediaListContainer = "sample-module--socialMediaListContainer--91178";
export var blogParagraph = "sample-module--blogParagraph--cbc02";
export var imagesContainer = "sample-module--imagesContainer--11d33";
export var imageContainer = "sample-module--imageContainer--a0253";
export var blogSubHeader = "sample-module--blogSubHeader--8a8d7";
export var content = "sample-module--content--4537c";