import React from 'react';
import { navigate } from 'gatsby';
import * as styles from './sample.module.css';

import Blog from '../../components/Blog';
import Container from '../../components/Container';
import Layout from '../../components/Layout/Layout';
import Icon from '../../components/Icons/Icon';
import Button from '../../components/Button';

const SamplePage = (props) => {
  return (
    <Layout>
      <div className={styles.root}>
        <Container>
          <div className={styles.blogContainer}>
            <Blog
              category={'LIFESTYLE'}
              title={'The Joy of Less: A Minimalist Guide to Declutter, Organize, and Simplify'}
              image={'/books/the_joy_of_less.jpg'}
              alt={''}
            >
              <div className={styles.content}>
              <br></br>
              <h2>ENG</h2>
              <br></br>
              <p className={styles.excerpt}>

              "The Joy of Less: A Minimalist Guide to Declutter, Organize, and Simplify" by Francine Jay is a comprehensive guide to living a simpler, more meaningful life by adopting a minimalist lifestyle. The book explores the benefits of living with less, and provides practical advice on how to declutter your home and create more space, both physically and mentally.

              </p>
              <br></br>
              <p className={styles.excerpt}>

              Jay begins by explaining the philosophy of minimalism, which is essentially the idea that less is more. She argues that we live in a society that values material possessions above all else, and that this has led to a culture of consumerism that is both wasteful and unsatisfying. By contrast, minimalism offers an alternative way of living that focuses on the things that truly matter, such as relationships, experiences, and personal growth.
                  
              </p>
              <br></br>
              <p className={styles.excerpt}>

              The book then moves on to the practical aspects of decluttering and organizing your home. Jay provides a step-by-step process for going through your possessions and deciding what to keep, what to donate, and what to throw away. She emphasizes the importance of being mindful and intentional about the things we choose to keep, and suggests that we should only hold onto items that are truly meaningful or useful to us.

              </p>
              <br></br>
              <p className={styles.excerpt}>

              Jay also offers advice on how to organize your remaining possessions in a way that is both functional and aesthetically pleasing. She suggests that we should aim to create a space that is clutter-free, visually appealing, and easy to maintain. This involves finding the right storage solutions, creating designated spaces for different types of items, and developing good habits for staying organized.

              </p>
              <br></br>
              <p className={styles.excerpt}>

              In addition to decluttering and organizing our physical possessions, Jay also encourages us to simplify our lives in other ways. This might involve simplifying our schedules, reducing our commitments, or even cutting back on our use of technology. The goal is to create more space and time in our lives for the things that truly matter, such as spending time with loved ones, pursuing our passions, or simply enjoying the present moment.

              </p>
              <br></br>
              <p className={styles.excerpt}>

              Throughout the book, Jay emphasizes the many benefits of living a minimalist lifestyle. She argues that minimalism can help us to reduce stress, increase happiness, and improve our overall well-being. By simplifying our lives, we can create more time and space for the things that truly matter, and develop a greater sense of purpose and meaning.

              </p>
              <br></br>
              <p className={styles.excerpt}>

              Overall, "The Joy of Less" is an excellent resource for anyone looking to simplify their life and adopt a more minimalist approach. Jay's practical advice and inspiring insights make this book a valuable tool for anyone who wants to declutter their home, streamline their possessions, and live a more meaningful life.

              </p>
              </div>

              <div className={styles.content}>
              <br></br>
              <h2>LV</h2>
              <br></br>
              <p className={styles.excerpt}>

              Frensīnas Džejas "The Joy of Less: Minimalist Guide to Declutter, Organize and Simplify" ir visaptverošs ceļvedis, kā dzīvot vienkāršāku, jēgpilnāku dzīvi, pieņemot minimālisma dzīvesveidu. Grāmatā tiek pētītas priekšrocības, ko sniedz dzīve ar mazāku summu, un sniegti praktiski padomi, kā sakārtot māju un radīt vairāk vietas gan fiziski, gan garīgi.

              </p>
              <br></br>
              <p className={styles.excerpt}>

              Džejs sāk, skaidrojot minimālisma filozofiju, kas būtībā ir ideja, ka mazāk ir vairāk. Viņa apgalvo, ka mēs dzīvojam sabiedrībā, kas augstāk par visu vērtē materiālo īpašumu, un tas ir novedis pie patērētāja kultūras, kas ir gan izšķērdīga, gan neapmierinoša. Turpretim minimālisms piedāvā alternatīvu dzīvesveidu, kas koncentrējas uz patiesi svarīgām lietām, piemēram, attiecībām, pieredzi un personīgo izaugsmi.

              </p>
              <br></br>
              <p className={styles.excerpt}>

              Pēc tam grāmata pāriet uz praktiskiem aspektiem, kas saistīti ar jūsu mājas pārkārtošanu un sakārtošanu. Džejs nodrošina soli pa solim procesu, kā izpētīt savu īpašumu un izlemt, ko paturēt, ko ziedot un ko izmest. Viņa uzsver, cik svarīgi ir būt uzmanīgiem un mērķtiecīgiem attiecībā uz lietām, ko izvēlamies paturēt, un iesaka turēt tikai tos priekšmetus, kas mums ir patiesi nozīmīgi vai noderīgi.
                  
              </p>
              <br></br>
              <p className={styles.excerpt}>

              Džejs arī sniedz padomus, kā sakārtot savu atlikušo mantu tā, lai tas būtu gan funkcionāls, gan estētiski pievilcīgs. Viņa ierosina, ka mums jācenšas izveidot telpu, kas ir bez traucējumiem, vizuāli pievilcīga un viegli kopjama. Tas ietver pareizo uzglabāšanas risinājumu atrašanu, īpašu vietu izveidi dažāda veida priekšmetiem un labu ieradumu attīstīšanu, lai uzturētu kārtību.
              </p>
              <br></br>
              <p className={styles.excerpt}>

              Papildus mūsu fizisko mantu sakārtošanai un sakārtošanai Džejs mudina mūs vienkāršot savu dzīvi arī citos veidos. Tas varētu ietvert mūsu grafiku vienkāršošanu, saistību samazināšanu vai pat tehnoloģiju izmantošanas samazināšanu. Mērķis ir radīt mūsu dzīvē vairāk vietas un laika lietām, kas patiešām ir svarīgas, piemēram, pavadīt laiku ar mīļajiem, īstenot savas kaislības vai vienkārši izbaudīt pašreizējo mirkli.

              </p>
              <br></br>
              <p className={styles.excerpt}>

              Visā grāmatā Džejs uzsver daudzās minimālisma dzīvesveida priekšrocības. Viņa apgalvo, ka minimālisms var palīdzēt mums samazināt stresu, palielināt laimi un uzlabot mūsu vispārējo labklājību. Vienkāršojot savu dzīvi, mēs varam radīt vairāk laika un vietas lietām, kas patiešām ir svarīgas, un attīstīt lielāku mērķa un jēgas sajūtu.

              </p>
              <br></br>
              <p className={styles.excerpt}>

              Kopumā "The Joy of Less" ir lielisks resurss ikvienam, kas vēlas vienkāršot savu dzīvi un pieņemt minimālistiskāku pieeju. Džeja praktiskie padomi un iedvesmojošās atziņas padara šo grāmatu par vērtīgu rīku ikvienam, kurš vēlas sakārtot savu māju, sakārtot savu īpašumu un dzīvot jēgpilnāku dzīvi.

              </p>
              </div>
            </Blog>
          </div>
        </Container>
      </div>
    </Layout>
  );
};

export default SamplePage;
